import * as React from 'react'
import { Input, type InputProps } from '../ui/input.tsx'
import { type MaskFunction } from './masks.ts'

export interface InputMaskProps extends InputProps {
  mask?: MaskFunction
}

const InputMask = React.forwardRef<HTMLInputElement, InputMaskProps>(
  ({ mask, onChange, ...props }, ref) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target

      const maskedValue = mask ? mask(value) : value
      e.target.value = maskedValue

      const syntheticEvent = {
        ...e,
        target: {
          ...e.target,
          value: maskedValue,
        },
      }

      if (onChange) {
        onChange(syntheticEvent as React.ChangeEvent<HTMLInputElement>)
      }
    }

    return <Input {...props} ref={ref} onChange={handleChange} />
  },
)

InputMask.displayName = 'InputMask'

export { InputMask }
