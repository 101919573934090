// masks.ts

export type MaskFunction = (value: string) => string

// Máscara para CPF
export const cpfMask: MaskFunction = (value: string) => {
  return value
    .replace(/\D/g, '') // Remove todos os caracteres que não são dígitos
    .replace(/(\d{3})(\d)/, '$1.$2') // Adiciona ponto após os três primeiros dígitos
    .replace(/(\d{3})(\d)/, '$1.$2') // Adiciona outro ponto após os próximos três dígitos
    .replace(/(\d{3})(\d{1,2})$/, '$1-$2') // Adiciona hífen antes dos dois últimos dígitos
    .slice(0, 14) // Limita o comprimento para 14 caracteres
}

// Máscara para telefone brasileiro
export const phoneMask: MaskFunction = (value: string) => {
  value = value.replace(/\D/g, '') // Remove todos os caracteres que não são dígitos

  if (value.length > 11) value = value.slice(0, 11) // Limita a 11 dígitos

  if (value.length > 10) {
    value = value.replace(/^(\d{2})(\d{1})(\d{4})(\d{4})/, '($1) $2$3-$4') // 11 dígitos
  } else if (value.length > 6) {
    value = value.replace(/^(\d{2})(\d{4})(\d{0,4})/, '($1) $2-$3') // 7-10 dígitos
  } else if (value.length > 2) {
    value = value.replace(/^(\d{2})(\d{0,4})/, '($1) $2') // 3-6 dígitos
  } else if (value.length > 1) {
    value = value.replace(/^(\d{0,2})/, '($1') // 1-2 dígitos (DDD)
  }

  return value
}
